import React, { memo, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, CardBody, Row } from "reactstrap";
import CardField from "../CardField/CardField";
import Icon from "../Icon";
import { createStructuredSelector } from "reselect";
import { useNavigate } from "react-router-dom";
import "./CommonCaseCard.css";
import { makeSelectCaseSearchValue } from "../../containers/SearchCase/selectors";
import { compose } from "redux";
import { makeMultiSelectCases } from "../../containers/OpenCases/selectors";
import { getEmployeeRole } from "../../containers/App/selectors";
import { imageBaseUrl } from "../../utils/constants";

export const CommonCaseCard = ({
  cardDetail,
  btn,
  caseType,
  searchValue,
  path,
  showCaseType,
  buttonClass,
  navigateParams,
  employeeRole,
  caseidArr,
  handleMultiCaseSelect,
  showSelect,
}) => {
  const navigate = useNavigate();
  const caseNumber = useMemo(
    () => cardDetail.find((item) => item.label === "Case Num")?.value || "-",
    [cardDetail]
  );
  const caseNo = useMemo(
    () => cardDetail.find((item) => item.label === "Case No.")?.value || "-",
    [cardDetail]
  );
  const navigateToDetails = useCallback(() => {
    const subProduct = cardDetail.find(
      (item) => item.label === "Sub-Product"
    ).value;
    navigate(
      path +
        "?" +
        btoa(
          `case=${caseNo}&subProduct=${subProduct}${
            navigateParams || ""
          }&casetype=${caseType}`
        )
    );
  }, [caseNo, cardDetail, navigateParams]);

  return (
    <>
      <Card
        className={
          showSelect && !caseidArr?.includes(caseNumber)
            ? "select-card-wrap"
            : ""
        }
      >
        {showSelect && employeeRole === "vendor" && (
          <button
            data-testid="handleMultiCaseSelect"
            className="card-shadow"
            onClick={() => handleMultiCaseSelect(caseNo)}
          >
            <div className="center-circle">
              {caseidArr?.includes(caseNo) && (
                <img
                  src={`${imageBaseUrl}/white-circle-tick.svg`}
                  alt="CircleTick"
                />
              )}
            </div>
          </button>
        )}
        {showCaseType && (
          <div className={`caseTypeName${caseType}`}>{caseType}</div>
        )}
        <CardBody style={{ position: "relative" }}>
          <Row style={{ padding: 0, margin: 0 }}>
            <div className="card-case">
              <span className="card-case-value">Case No. :</span>{" "}
              <span
                className={
                  searchValue && caseNumber === searchValue
                    ? "card-case-value highlight"
                    : "card-case-value"
                }
              >
                {caseNumber}
              </span>
            </div>
            {cardDetail?.length > 0 &&
              cardDetail?.map((item, index) => {
                if (index > 1)
                  return (
                    <CardField key={item?.label} item={item} index={index} />
                  );
              })}
          </Row>
          {btn && (
            <div className="details-view-btn">
              <button
                data-testid="btn-view"
                className={`${buttonClass} view-btn`}
                onClick={navigateToDetails}
              >
                <Icon /> View
              </button>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};

CommonCaseCard.propTypes = {
  cardDetail: PropTypes.array,
  btn: PropTypes.bool,
  caseType: PropTypes.string,
  searchValue: PropTypes.string,
  path: PropTypes.string,
  showCaseType: PropTypes.bool,
  buttonClass: PropTypes.string,
  navigateParams: PropTypes.string,
  employeeRole: PropTypes.string,
  caseidArr: PropTypes.array,
  handleMultiCaseSelect: PropTypes.func,
  showSelect: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  searchValue: makeSelectCaseSearchValue(),
  caseidArr: makeMultiSelectCases(),
  employeeRole: getEmployeeRole(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(CommonCaseCard);
