import React, { useEffect, useState, memo } from 'react';
import Icon from "../../../../components/Icon";
import './index.css';
import { imageBaseUrl } from '../../../../utils/constants';

export const Challan = props => {
  const [External, setExternal] = useState(props.External);
  const [showmore, setshowmore] = useState(false);
  useEffect(() => {
    updateInitialChallanDetails();
  },[]);

    const updateInitialChallanDetails = () => {
        const updatedChallanDetails = External && External.challanDetails && External.challanDetails.map(obj => {
            return { ...obj, displayVisible: false };
        });
        setExternal(prevChallanInfo => ({
            ...prevChallanInfo,
            challanDetails: updatedChallanDetails
        }));
    };

  const handleShowmore = () =>{
    setshowmore(true);
  }

  const handleShowless = () =>{
    setshowmore(false);
  }

    const updateChallanDetails = (challanNumber,value) => {
        const updatedChallanDetails = External && External.challanDetails && External.challanDetails.map(obj => {
            if (obj.challanNumber === challanNumber) {
                return { ...obj, displayVisible: !value };
            }
            return obj;
        });
        setExternal(prevChallanInfo => ({
            ...prevChallanInfo,
            challanDetails: updatedChallanDetails
        }));
    };

    return (
        <div className='basic' style={{ width: '100%' }}>
            <div className='map-data'>
                <div className='externalMainView-alloted'>
                    <div className='externalMainView2'>
                        <div className='externalimg-view1'>
                            <div className='phcar-external-img'>
                                <img style={{ width: "20px", height: "20px" }} src={`${imageBaseUrl}/ph_car.png`} alt="search" />
                            </div>
                            <div className='externalHeader-style'>Challan</div>
                        </div>
                        {showmore ?
                            <div>
                                <button data-testid="handleShowless1-view" className='claim-upbuttton-style' onClick={() => { handleShowless(); }}>
                                    <img className='claim-upbuttton-img' src={`${imageBaseUrl}/allotted-claim-up.png`} alt="allotted" />
                                </button>
                            </div>
                            :
                            <div className='map-data-breakDIV claimbtn-main externalimg-view2' style={{ marginTop: 0 }}>
                                <button data-testid="handleShowAllbtn-view" className='claimview-btn' onClick={() => { handleShowmore(); }}><Icon />  View</button>
                            </div>
                        }
                    </div>
                    {showmore && <div className='challansmaintext'>Total Number of Challans - 12</div>}
                    {showmore ?
                        <div>
                            {External && External.challanDetails && External.challanDetails.length > 0 && External.challanDetails.map((item, i) => (
                                <div>
                                    <div className={item.displayVisible ? item.challanStatus && item.challanStatus.toLowerCase() === 'paid' ? 'chanllandetails-divison-mainheading4' : 'chanllandetails-divison-mainheading2': 'chanllandetails-divison-mainheading5'}>
                                        <div className={item.displayVisible ?'chanllandetails-divison-mainheading' :'chanllandetails-divison-mainheading11'}>Challan {item.number} - {item.offenseDetails && item.offenseDetails.length > 30 ? item.offenseDetails.slice(0, 30) + '...' : item.offenseDetails}</div>
                                        <div className='chanllandetails-divison-mainheading3' >
                                            <button data-testid="handleShowless3-view" className='claim-upbuttton-style' onClick={() => updateChallanDetails(item.challanNumber,item.displayVisible)}>
                                                <img className='chanllandetails-upbuttton-img' src={item.displayVisible ?  item.challanStatus && item.challanStatus.toLowerCase() === 'paid' ? `${imageBaseUrl}/Icon.png`: `${imageBaseUrl}/Iconred.png`: `${imageBaseUrl}/Iconinactive.png`} />
                                            </button>
                                        </div>
                                    </div>
                                    {item.displayVisible ? (
                                        <div className='chanllandetails-divison-main'>
                                            <div className='chanllandetails-divison'>
                                                <div className='chanllandetails-divison-text1'>Challan Number:</div>
                                                <div className='chanllandetails-divison-text2'>{item.challanNumber}</div>
                                            </div>
                                            <div className='chanllandetails-divison'>
                                                <div className='chanllandetails-divison-text1'>Challan Date:</div>
                                                <div className='chanllandetails-divison-text2'>{item.challanDate}</div>
                                            </div>
                                            <div className='chanllandetails-divison'>
                                                <div className='chanllandetails-divison-text1'>State:</div>
                                                <div className='chanllandetails-divison-text2'>{item.state}</div>
                                            </div>
                                            <div className='chanllandetails-divison'>
                                                <div className='chanllandetails-divison-text1'>Amount:</div>
                                                <div className='chanllandetails-divison-text2'>{item.amount}</div>
                                            </div>
                                            <div className='chanllandetails-divison'>
                                                <div className='chanllandetails-divison-text1'>Challan Status:</div>
                                                <div className='chanllandetails-divison-text2'>{item.challanStatus}</div>
                                            </div>
                                            <div className='chanllandetails-divison'>
                                                <div className='chanllandetails-divison-text1'>Offense Details:</div>
                                                <div className='chanllandetails-divison-text2'>{item.offenseDetails ? item.offenseDetails : '-'}</div>
                                            </div>
                                            <div className='chanllandetails-divison'>
                                                <div className='chanllandetails-divison-text1'>Accused Name:</div>
                                                <div className='chanllandetails-divison-text2'>{item.offenseDetails ? item.offenseDetails : '-'}</div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ))
                            }
                        </div> : null}
                    {showmore ?
                        <div style={{ float: 'right' }}>
                            <button data-testid="handleShowless2-view" className='claim-upbuttton-style' onClick={() => { handleShowless(); }}>
                                <img className='claim-upbuttton-img' src={`${imageBaseUrl}/allotted-claim-up.png`} alt="allotted" />
                            </button>
                        </div> : null}
                </div>
            </div>
        </div>
    )
}

Challan.propTypes = {}

export default memo(Challan);