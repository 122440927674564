import React, { memo } from 'react';
import { Navbar, NavbarBrand, } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import path from '../../constants/pathConstants';
import './index.css'
import NavbarBackButton from './navbarBackbutton';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { imageBaseUrl } from '../../utils/constants';
export function CommonNavbar({ title, backButton, search, download, navbarname, filterToggle, moreOptionPopup=false, onDownloadClick}) {
    const navigate = useNavigate();
    let isdownload = download !== undefined ? download : false;
    let navbarnameData = navbarname ? navbarname :'';
    const setbackbutton = () => backButton ? navigate(backButton) : navigate(-1);
    return (
        <div className={'navbar-container commonnavbar '+ navbarnameData} data-test="commonnavbar">
            <Navbar>
                <NavbarBrand onClick={setbackbutton} data-test="back-icon" className='d-flex' style={{cursor: "pointer"}}>
                    <NavbarBackButton />
                    <div className='title' title={title}>{title}</div>
                </NavbarBrand>
                <span>
                    {search && <img className='search-icon' onClick={() => navigate(path.EMPLOYEMENT_SEARCH)} src={`${imageBaseUrl}/search.svg`} alt="message" />}
                    {isdownload && <img className='bell-icon' onClick={onDownloadClick} src={`${imageBaseUrl}/download.png`} alt="download" />}
                    {moreOptionPopup && filterToggle && <img className='bell-icon' onClick={filterToggle} src={`${imageBaseUrl}/dot.png`} alt="dot" />}
                    <img className='bell-icon' onClick={() => navigate(path.COMING_SOON + "?" + btoa("title=Notification"))} src={`${imageBaseUrl}/bell.svg`} alt="notification" />
                </span>
            </Navbar>
        </div>
    )
}
CommonNavbar.propTypes = {
    title: propTypes.string,
    backButton: propTypes.string,
    navbarname: propTypes.string,
    search: propTypes.bool,
    download: propTypes.bool,
    dispatch: propTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
});

export function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect, memo,)(CommonNavbar);