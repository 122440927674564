import React, { useState,useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.css';
import { imageBaseUrl } from '../../utils/constants';

export function SuccessPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.state.stateVal;

  const [text, setText] = useState('Record Updated Successfully!');
  useEffect(() => {
    if(location?.text) { 
      setText(atob(location?.text))
    }
  }, [])
  
  return (
    <div className='success-page'>
        <img src={`${imageBaseUrl}/tick1.png`} alt="tick" />
        <span className='success-page-title'>{text}</span>
        <button data-testid="SuccessPage" className='success-page-btn' onClick={()=>navigate(path)}>Proceed</button>
    </div>
  )
}

export default SuccessPage;